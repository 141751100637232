<template>
  <a-card>
    <div class="d-flex justify-content-between align-items-center mb-3">
      <span class="h5 mb-0" style="width: 100%">Deeplinks</span>
      <a-popconfirm
        title="Sure to delete?"
        v-if="this.selectedRowKeys.length"
        @confirm="deleteSelected"
        :disabled="this.deleting"
      >
          <a-button type="danger" size="large" style="font-size: 16px; font-weight: 300; border-radius: 2px;">
            <a-icon type="delete" /> Delete
          </a-button>
      </a-popconfirm>
      <a-button type="primary" class="ml-1" size="large" style="font-size: 16px; font-weight: 300; border-radius: 2px;" @click="showLinkModal" :disabled="this.deleting">
        <a-icon type="plus" /> Add New
      </a-button>
    </div>
    <a-icon type="loading" class="text-primary" style="font-size: 48px" v-if="this.deleting" />
    <a-table
      :columns="linkColumns"
      :data-source="linkData"
      :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
      :pagination="{current: this.current}"
      @change="handleTableChange"
      v-else
    >
      <template
        v-for="col in ['name', 'link']"
        :slot="col"
        slot-scope="text, record"
      >
        <div :key="col">
          <a-input
            v-if="record.editable"
            style="margin: -5px 0"
            :value="text"
            @change="e => handleLinkChange(e.target.value, record.id, col)"
          />
          <template v-else>
            {{ text }}
          </template>
        </div>
      </template>
      <template slot="operation" slot-scope="text, record">
        <div class="editable-row-operations" v-if="checkPermissions(user.permissions, 'settings', 'edit')">
          <span v-if="record.editable">
            <a @click="() => saveLink(record.id)" class="text-success"><a-icon type="save" /> Save</a>
            <br>
            <a-popconfirm title="Sure to cancel?" @confirm="() => cancelLink(record.id)">
              <a class="text-danger"><a-icon type="close" /> Cancel</a>
            </a-popconfirm>
          </span>
          <span v-else>
            <a href="javascript:;" class="text-primary font-size-18" :disabled="editingLinkKey !== ''" @click="() => editLink(record.id)">
              <a-icon type="edit" />
            </a>
          </span>
          <span v-if="!record.editable">
            <a-popconfirm
              v-if="linkData.length"
              title="Sure to delete?"
              @confirm="() => onDeleteLink(record.id)"
            >
              <a href="javascript:;" class="text-danger font-size-18">
                <a-icon type="delete" />
              </a>
            </a-popconfirm>
          </span>
        </div>
      </template>
    </a-table>
    <!-- <a-modal v-model="newLinkModal"
      title="Add New Deeplink"
      ok-text="Ok"
      cancel-text="Cancel"
      @ok="addLinkSubmit"
      class="add-link-modal olimp-admin-modal"
      width="520px"
      :destroyOnClose="true"
    > -->
    <a-modal v-model="newLinkModal"
      title="Add New Deeplink"
      class="add-link-modal olimp-admin-modal"
      width="520px"
      :destroyOnClose="true"
      footer=''
    >
      <a-form-model
        ref="addLinkForm"
        :model="addLinkForm"
        :rules="addLinkRules"
      >
        <a-form-model-item ref="name" label="Friendly name" prop="name">
          <a-input v-model="addLinkForm.name" placeholder="Input friendly name" />
        </a-form-model-item>
        <a-form-model-item ref="link" label="Link" prop="link">
          <a-input v-model="addLinkForm.link" placeholder="Input link" />
        </a-form-model-item>
      </a-form-model>
      <hr>
      <div class="d-flex">
        <a-col class="text-left" :span="12">
          <CustomButton @click="hideLinkModal">
            Cancel
          </CustomButton>
        </a-col>
        <a-col class="text-right" :span="12">
          <a-button type="primary" @click="addLinkSubmit">
            Add
          </a-button>
        </a-col>
      </div>
    </a-modal>
  </a-card>
</template>

<script>
import { mapState } from 'vuex'
import apiClient from '@/services/axios'
import banmix from '@/services/bannermix.js'
import accessMix from '@/services/accessmix.js'
import CustomButton from '@/components/custom/button/CustomButton'

const linkColumns = [
  {
    title: 'Friendly Name',
    dataIndex: 'name',
    width: '25%',
    scopedSlots: { customRender: 'name' },
  },
  {
    title: 'Link',
    dataIndex: 'link',
    width: '55%',
    scopedSlots: { customRender: 'link' },
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    width: '30%',
    align: 'right',
    scopedSlots: { customRender: 'operation' },
  },
]
var linkData = []
function getDeeplinks() {
  const url = '/admin/deeplinks'
  apiClient.get(url).then((response) => {
    linkData = response.data.data
  }).catch(error => { console.log(error) })
}
getDeeplinks()

export default {
  name: 'deeplinks',
  mixins: [banmix, accessMix],
  components: {
    CustomButton,
  },
  computed: {
    ...mapState(['user']),
    ...mapState(['settings']),
  },
  data() {
    this.cacheLinkData = linkData.map(item => ({ ...item }))
    return {
      selectedRowKeys: [],
      linkData,
      pageSize: 10,
      current: 1,
      linkColumns,
      deleting: false,
      editingLinkKey: '',
      newLinkModal: false,
      addLinkForm: {
        name: '',
        link: '',
      },
      addLinkRules: {
        name: [{ required: true, message: 'Please input friendly name', trigger: 'change' }],
        link: [{ required: true, message: 'Please input link', trigger: 'change' }],
      },
    }
  },
  mounted () {
    this.getDeeplinks()
  },
  methods: {
    setDeleting(state) {
      this.deleting = state
    },
    clearSelection() {
      this.selectedRowKeys = []
    },
    handleTableChange(pagination, filters, sorter) {
      this.pageSize = pagination.pageSize
      this.current = pagination.current
      this.clearSelection()
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    deleteSelected() {
      this.setDeleting(true)
      const url = '/admin/deeplinks'
      const delta = ((this.current ?? 1) - 1) * this.pageSize
      const items = []
      this.selectedRowKeys.map(index => {
        items.push(linkData[index + delta].id)
      })
      const payload = { ...items }
      apiClient.delete(url, { params: payload }).then((response) => {
        this.linkData = response.data.data
        this.cacheLinkData = this.linkData.map(item => ({ ...item }))
        linkData = this.linkData
        this.setDeleting(false)
      }).catch((error) => {
        this.setDeleting(false)
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting deeplink',
          description: error.message,
        })
      })
      this.clearSelection()
    },
    getDeeplinks() {
      const url = '/admin/deeplinks'
      apiClient.get(url).then((response) => {
        this.linkData = response.data.data
        this.cacheLinkData = this.linkData.map(item => ({ ...item }))
        linkData = this.linkData
      }).catch(error => { console.log(error) })
    },
    showLinkModal() {
      this.newLinkModal = true
    },
    hideLinkModal() {
      this.newLinkModal = false
    },
    resetAddLinkForm() {
      this.$refs.addLinkForm.resetFields()
    },
    addLinkSubmit() {
      this.$refs.addLinkForm.validate(valid => {
        if (valid) {
          const url = '/admin/deeplinks'
          return apiClient.post(url, this.addLinkForm).then((response) => {
            this.$notification.success({
              message: '"' + response.data.data.name + '" deeplink successfully created',
              description: '',
            })
            const { linkData } = this
            const newLink = response.data.data
            this.linkData = [...linkData, newLink]
            this.cacheLinkData = this.linkData.map(item => ({ ...item }))
            this.resetAddLinkForm()
            this.hideLinkModal()
          }).catch(error => {
            console.log(error)
            this.$notification.error({
              message: 'Error while creating new deeplink',
              description: error.message,
            })
          })
        } else {
          return false
        }
      })
    },
    handleLinkChange(value, key, column) {
      const newData = [...this.linkData]
      const target = newData.filter(item => key === item.id)[0]
      if (target) {
        target[column] = value
        this.linkData = newData
      }
    },
    onDeleteLink(key) {
      this.setDeleting(true)
      const dataSource = [...this.linkData]
      const target = dataSource.filter(item => key === item.id)[0]
      const url = '/admin/deeplinks/' + key
      apiClient.delete(url).then((response) => {
        this.setDeleting(false)
        this.$notification.success({
          message: 'Deeplink "' + target.name + '" successfully deleted',
          description: '',
        })
        this.linkData = dataSource.filter(item => item.id !== key)
        this.cacheLinkData = this.linkData.map(item => ({ ...item }))
      }).catch(error => {
        this.setDeleting(false)
        console.log(error)
        this.$notification.error({
          message: 'Error while deleting deeplink',
          description: error.message,
        })
      })
    },
    editLink(key) {
      console.log(key)
      const newData = [...this.linkData]
      const target = newData.filter(item => key === item.id)[0]
      this.editingLinkKey = key
      if (target) {
        target.editable = true
        this.linkData = newData
      }
    },
    saveLink(key) {
      const newData = [...this.linkData]
      const newCacheData = [...this.cacheLinkData]
      const target = newData.filter(item => key === item.id)[0]
      const targetCache = newCacheData.filter(item => key === item.id)[0]
      if (target && targetCache) {
        const url = '/admin/deeplinks/' + target.id
        return apiClient.patch(url, target).then((response) => {
          this.$notification.success({
            message: '"' + response.data.data.name + '" deeplink successfully updated',
            description: '',
          })
          delete target.editable
          this.linkData = newData
          Object.assign(targetCache, target)
          this.cacheLinkData = newCacheData
          this.editingLinkKey = ''
        }).catch(error => {
          console.log(error)
          this.$notification.error({
            message: 'Error while updating deeplink',
            description: error.message,
          })
        })
      }
      this.editingLinkKey = ''
    },
    cancelLink(key) {
      const newData = [...this.linkData]
      const target = newData.filter(item => key === item.id)[0]
      this.editingLinkKey = ''
      if (target) {
        Object.assign(target, this.cacheLinkData.filter(item => key === item.id)[0])
        delete target.editable
        this.linkData = newData
      }
    },
  },
}
</script>
<style scoped>
  .editable-row-operations a {
    margin-right: 8px;
  }
</style>
